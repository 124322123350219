<template>
    <div>
        <div >
            <div style="text-align:center;margin-top:20px">
                <el-button @click="showTime1" :class="{ 'my-tracks-btn': true, 'isActive': follow }">我的关注</el-button>
                <el-button @click="showTime2" :class="{ 'my-tracks-btn': true, 'isActive': footprint }">浏览足迹</el-button>
            </div>
        </div>
        <div style="margin-top:20px;padding-left:20px;" v-if="footprint">

            <el-tabs v-model="activeName" @tab-click="handleClick2">
                    <el-tab-pane name="first">
                        <span style="color:black"  slot="label" >租房</span>
                        
                        <div>
                           <ul class="nearby-houses-ul">
                                <li v-for="item in footprintList">
                                    <router-link class="link" target="_blank" :to="{path:'/rentHouseDetails',query: { roomId: item.roomId}}">
                                        <div><img class="nearby-houses-img" :src="item.showPictures" alt=""></div>
                                        <div class="nearby-houses-text">
                                            <div style="width:180px">{{item.estateName}}/{{item.roomName}}</div> 
                                        </div>
                                        <div>
                                            <div class="nearby-houses-describe">{{item.roomType}}</div>
                                            <div class="nearby-houses-company">元/月</div>
                                            <div class="nearby-houses-price">{{item.roomPrice}}</div>
                                            
                                        </div>
                                    </router-link>
                                    
                                </li>
                            </ul>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane name="second">
                        <span style="color:black" slot="label" >商铺</span>
                        
                        <div>
                            <ul class="nearby-houses-ul">
                                <li v-for="item in footprintList">
                                    <router-link class="link" target="_blank" :to="{path:'/rentHouseDetails',query: { roomId: item.roomId}}">
                                        <div><img class="nearby-houses-img" :src="item.showPictures" alt=""></div>
                                        <div class="nearby-houses-text">
                                            <div style="width:180px">{{item.estateName}}/{{item.roomName}}</div> 
                                        </div>
                                        <div>
                                            <div class="nearby-houses-describe">{{item.roomType}}</div>
                                            <div class="nearby-houses-company">元/月</div>
                                            <div class="nearby-houses-price">{{item.roomPrice}}</div>
                                            
                                        </div>
                                    </router-link>
                                    
                                </li>
                            </ul>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane name="third">
                        <span style="color:black" slot="label" >办公用房</span>
                        
                        <div>
                            <ul class="nearby-houses-ul">
                                <li v-for="item in footprintList">
                                    <router-link class="link" target="_blank" :to="{path:'/rentHouseDetails',query: { roomId: item.roomId}}">
                                        <div><img class="nearby-houses-img" :src="item.showPictures" alt=""></div>
                                        <div class="nearby-houses-text">
                                            <div style="width:180px">{{item.estateName}}/{{item.roomName}}</div> 
                                        </div>
                                        <div>
                                            <div class="nearby-houses-describe">{{item.roomType}}</div>
                                            <div class="nearby-houses-company">元/月</div>
                                            <div class="nearby-houses-price">{{item.roomPrice}}</div>
                                            
                                        </div>
                                    </router-link>
                                    
                                </li>
                            </ul>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            <!-- <ul class="nearby-houses-ul">
                <li v-for="item in footprintList">
                    <router-link class="link" target="_blank" :to="{path:'/rentHouseDetails',query: { roomId: item.roomId}}">
                        <div><img class="nearby-houses-img" :src="item.showPictures" alt=""></div>
                        <div class="nearby-houses-text">镇北街道  碧桂园</div>
                        <div>
                            <div class="nearby-houses-describe">{{item.roomType}}</div>
                            <div class="nearby-houses-price">{{item.roomPrice}}</div>
                            <div class="nearby-houses-company">元/月</div>
                        </div>
                    </router-link>
                    
                </li>
            </ul> -->
        </div>
        <div v-if="follow">
            <div class="follow-box">
                共<span class="follow-number">{{count}}</span>套关注房源
            </div>
            <div style="padding-left:20px;margin-top:10px">
                 <el-tabs v-model="activeName" @tab-click="handleClick1">
                    <el-tab-pane name="first">
                        <span style="color:black"  slot="label" >租房</span>
                        
                        <div>
                            <ul class="nearby-houses-ul">
                                    <li v-for="item in followList">
                                    <router-link class="link" target="_blank" :to="{path:'/rentHouseDetails',query: { roomId: item.roomId}}">
                                        <div><img class="nearby-houses-img" :src="item.showPictures" alt=""></div>
                                        <div class="nearby-houses-text">
                                            <div style="width:180px">{{item.estateName}}/{{item.roomName}}</div> 
                                        </div>
                                        <div>
                                            <div class="nearby-houses-describe">{{item.roomType}}</div>
                                            <div class="nearby-houses-company">元/月</div>
                                            <div class="nearby-houses-price">{{item.roomPrice}}</div>
                                            
                                        </div>
                                    </router-link>  
                                    </li>
                                </ul>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane name="second">
                        <span style="color:black" slot="label" >商铺</span>
                         
                        <div>
                            <ul class="nearby-houses-ul">
                                    <li v-for="item in followList">
                                    <router-link class="link" target="_blank" :to="{path:'/rentHouseDetails',query: { roomId: item.roomId}}">
                                        <div><img class="nearby-houses-img" :src="item.showPictures" alt=""></div>
                                        <div class="nearby-houses-text">
                                            <div style="width:180px">{{item.estateName}}/{{item.roomName}}</div> 
                                        </div>
                                        <div>
                                            <div class="nearby-houses-describe">{{item.roomType}}</div>
                                            <div class="nearby-houses-company">元/月</div>
                                            <div class="nearby-houses-price">{{item.roomPrice}}</div>
                                            
                                        </div>
                                    </router-link>  
                                    </li>
                                </ul>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane name="third">
                        <span style="color:black" slot="label" >办公用房</span>
                        
                        <div>
                            <ul class="nearby-houses-ul">
                                    <li v-for="item in followList">
                                    <router-link class="link" target="_blank" :to="{path:'/rentHouseDetails',query: { roomId: item.roomId}}">
                                        <div><img class="nearby-houses-img" :src="item.showPictures" alt=""></div>
                                        <div class="nearby-houses-text">
                                            <div style="width:180px">{{item.estateName}}/{{item.roomName}}</div>  
                                        </div>
                                        <div>
                                            <div class="nearby-houses-describe">{{item.roomType}}</div>
                                            <div class="nearby-houses-company">元/月</div>
                                            <div class="nearby-houses-price">{{item.roomPrice}}</div>
                                            
                                        </div>
                                    </router-link>  
                                    </li>
                                </ul>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import request from '../../api/index'
export default {
    name:"MyTracks",
    data(){
        return{
           follow:true,
           footprint:false, 
           activeName:'first',
           followList:[],
           footprintList:[],
           count:'',
           roomType:'',
        }
    },
    mounted() {
        this.transformation1('ZF')
        this.transformation2('ZF')
    },
    methods:{
        showTime1(){
            this.follow = true;
            this.footprint = false
            this.transformation1(this.roomType)
        },
        showTime2(){
            this.footprint = true;
            this.follow = false;
            this.transformation2(this.roomType)
        },
        transformation1(val){
            
            this.roomType = val;
            let params = {
                   "userId" : JSON.parse(localStorage.getItem('userId')),
                    "roomType" : this.roomType,
                    "history" : "Sc"
                    }
                    request.post('/operating-real-estate/user/getBrowsingHistory',params)
                    .then(
                        res=>{
                            console.log(res.result)
                            if (res.result == 0) {
                                this.followList = res.data.list;
                                this.count = res.data.count;
                                console.log(this.followList)
                            }
                        }
                    );
        },
        transformation2(val){
            this.roomType = val;
            let params = {
                   "userId" :JSON.parse(localStorage.getItem('userId')),
                    "roomType" : this.roomType,
                    "history" : "Zj"
                    }
                    request.post('/operating-real-estate/user/getBrowsingHistory',params)
                    .then(
                        res=>{
                            console.log(res.result)
                            if (res.result == 0) {
                                this.footprintList = res.data.list
                                console.log(this.footprintList)
                            }
                        }
                    );
        },


        handleClick1(tab, event) {
				console.log(tab, event);
				if (tab.name == 'first') {
					this.transformation1('ZF');
				} else if (tab.name == 'second') {
					this.transformation1('SP');
				} else{
                    this.transformation1('BG');
                }
			},
        handleClick2(tab, event) {
				console.log(tab, event);
				if (tab.name == 'first') {
					this.transformation2('ZF');
				} else if (tab.name == 'second') {
					this.transformation2('SP');
				} else{
                    this.transformation2('BG');
                }
			},
			
    }
}
</script>
<style lang="less" scoped>
    .isActive {
        background-color:#c6e2ff;
        color:#409EFF
    }
    .my-tracks-btn{
        margin: 0;
        border-radius: 0;
    }
    .follow-box{
        padding-left: 20px;
        height: 60px;
        font-size: 24px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333;
        line-height: 60px;
        border-bottom: 1px solid #E7E7E7;
    }
    .follow-number{
        color: #3094FF;
        margin: 0px 10px;
    }
    .nearby-houses-ul > li{
        margin-right: 15px;
    }
    .nearby-houses-text{

        display: -webkit-box;/*作为弹性伸缩盒子模型显示*/
        -webkit-line-clamp: 1; /*显示的行数；如果要设置2行加...则设置为2*/
        overflow: hidden; /*超出的文本隐藏*/
        text-overflow: ellipsis; /* 溢出用省略号*/
        -webkit-box-orient: vertical;/*伸缩盒子的子元素排列：从上到下*/
    }
</style>